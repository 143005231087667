.licensePreview {
  /* background-color: red; */
  padding-top: 2vw;
}
.licensePreview .document-Cards {
  display: flex;
  flex-direction: column;
}

.licensePreview .document-Cards .document-Card {
  background-color: white;
  box-shadow: 4px 4px 100px rgba(0, 0, 0, 0.09);
  padding: 0.8vw 1.2vw;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  text-align: left;
  margin: 1.3vw 0vw;
}
.license-document-content{
  margin-top: 2vw;
}

.license-document-content div {
  display: flex;
  background-color: white;
  border-radius: unset;
  width: fit-content;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  /* border: 1px solid black; */
  margin: 1vw 0vw;
}

.license-document-content div .document-key {
  width: 15vw;
  display: flex;
  /* background-color: orange; */
  /* font-weight: bolder; */
  font-size: 1.6vw;
  /* color:  #FE5010; */
}

.license-document-content div .dots {
  width: 2vw;
  /* background-color: purple; */
  font-size: 1vw;
  color: #fe5010;

  font-weight: bolder;
  font-family: "Jost", sans-serif;
}

.license-document-content div .document-Value {
  padding-left: 0.5vw;
  font-size: 1.3vw;
  font-weight: 100 !important;
}


.licensePreview .document-Cards .document-Card .card-img{
  display: flex;
  justify-content: flex-end;
  align-items: start;
  text-align: center;
  /* background-color: red ; */

}

.licensePreview .document-Cards .document-Card .card-img .delete-icon {
  cursor: pointer;
  position: absolute;
  display: flex;
  z-index: 1;
  /* border: 2px solid black; */
  box-shadow: 2px 3px 5px gray;
  padding:  0.5vw;
  border-radius: 50%;
  margin-right: -0.5vw;
  margin-top: -0.5vw;

  background-color: white;
}

.licensePreview .document-Cards .document-Card .card-img .delete-icon img {
  width: 1.5vw;
  display: flex;
  margin-left: auto;
  margin-top: 0.2vw;
}
.licensePreview .document-Cards .document-Card .card-img .document-img {
  /* border: 1px solid rgb(203, 203, 203); */
  box-shadow: 3px 5px 8px gray;
  /* padding: 0.8vw; */
  overflow: hidden;
  margin: 0.8vw 0vw;
  width: 25vw !important;
  height: 12vw !important;
  border-radius: 0.4vw !important;
  /* object-fit: cover; */
  display: flex;
  justify-content: center;
  align-items: center;
  object-position: center;
  cursor: pointer;
  opacity: 0.9;
}

.licensePreview .document-Cards .document-Card .card-img .document-img img {
  display: flex;
  object-fit: cover;
}

.document-Card .license-content {
  font-family: "Jost", sans-serif;
}

/* modal editing from here ... */

.document-image-shower {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 2vw 2vw;
}

.document-image-shower .nav,
.Delete-confirmation .nav {
  margin-left: auto;
  display: flex;
  cursor: pointer;
}
.document-image-shower .nav img,
.Delete-confirmation .nav img {
  width: 2vw;
}

.document-image-shower .document-modal-images {
  width: 30vw;
  height: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.document-modal-images img {
  /* width: 25vw; */
  cursor: not-allowed;
  /* height: fit-content; */
}

.Delete-confirmation {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 2vw;
}

.Delete-confirmation .delete-info {
  margin: 1vw 0vw;
}
.delete-info p {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  font-family: "Jost", sans-serif;
  font-size: 1.4vw;
}
.delete-info p h1 {
  margin: 0vw 1vw !important;
  font-size: 2.5vw;
}
.Delete-confirmation .delete-info h1 {
  font-family: "Jost", sans-serif;
  margin: 1vw 0vw;
  font-size: 1.8vw;
}

.Delete-confirmation .delete-info .buttons button {
  margin: 3vw 1vw 0vw 1vw;
  font-family: "Jost", sans-serif;
  font-size: 1vw;
  padding: 0.8vw 1.2vw;
  cursor: pointer;
  border: none;
  border-radius: 0.5vw;
}

.Delete-confirmation .delete-info .buttons .Delete {
  background-color: red;
  color: white;
}

.Delete-confirmation .delete-info .buttons .Cancel {
  background-color: gray;
  color: white;
}

.No-Documents {
  font-size: 2vw;
  font-family: "Jost", sans-serif;
}

.expriry-date-content {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: red;
  position: relative;
  top: 1.2vw;
  background-color: transparent !important;
  font-family: "JOST", sans-serif;
}

.expriry-date-content p{
    display: flex;
    text-align: center;
    justify-content: center;
}
.expriry-date-content p span{
    padding: 0vw 0.5vw;
}


.expiry-content-animation{
    display: flex;
    color: red;
    position: relative;
    top: 1.2vw;
    align-items: center;
    text-align: center;
    font-weight: bolder;
    letter-spacing: 0.1vw;
    font-family: "Jost",sans-serif;
    justify-content: center;
    animation: zoom-in-zoom-out 1s ease infinite;
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.9, 0.9);
  }
}

@media screen and (max-width: 768px) {
  .expriry-date-content p{
    font-size: 2vw;
  }
  .license-document-content div .document-key {
    width: 30vw;
    font-size: 3vw;
  }
  .license-document-content div .dots {
    width: 4vw;
    font-size: 3vw;
  }

  .license-document-content div .document-Value {
    font-size: 2.5vw;
  }

  .licensePreview .document-Cards .document-Card .card-img .document-img {
    width: 25vw !important;
    height: 14vw !important;
  }

  .licensePreview .document-Cards .document-Card .card-img .delete-icon img {
    width: 2.3vw;
  }
  .licensePreview .document-Cards .document-Card {
    margin: 2.3vw 2vw;
    border-radius: 2.5vw;
    padding: 1.8vw 3.2vw;
  }
  .Delete-confirmation {
    width: 70vw;
  }
  .document-image-shower .nav img,
  .Delete-confirmation .nav img {
    width: 3vw;
  }
  .delete-info p {
    font-size: 3.4vw;
  }
  .Delete-confirmation .delete-info h1 {
    font-size: 5vw;
  }
  .Delete-confirmation .delete-info .buttons button {
    font-size: 2.5vw;
    margin: 3vw 3vw 0vw 3vw;
  }

  .document-image-shower {
    width: 70vw;
  }
  .document-image-shower .document-modal-images {
    width: 60vw;
    height: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
    overflow: hidden;
  }
}
