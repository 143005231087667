* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
}

.ComponentContainer {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  bakground-color: #f2f5fb !important;
}
.selectionbox {
  padding: 1.4vw 0vw;
  height: 37.5vw;
  background-color: white;
  position: fixed;
  top: 0;
  margin-top: 8vw;
  border-radius: 0.9vw;
  bottom: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin-bottom: auto;
  max-width: 20vw;
}

.components {
  padding: 1vw;
  margin-left: 20.5vw;
  width: 80vw;
  background-color: #f2f5fb;
  margin-top: -1.5vw;
  border-radius: 0.9vw;
  overflow: hidden !important;
}
.components div {
  /* overflow: hidden  !important; */
  /* background-color: #F2F5FB; */
  border-radius: 0.9vw;
}
.selectionbox div {
  display: flex;
  width: 19vw;
  cursor: pointer;
  padding: 1.6vw;
}
.selectionbox .Hover:hover {
  background-color: #fe5010;
  color: white;
  transition-duration: 0.2s;
}
.selectionbox .Hover h1 {
  font-family: sans-serif;
  font-size: 2vw;
  /* padding: 0.4vw; */
}
.MainEle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.MainEle .ele {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.monthly {
  display: flex;
  background-color: #f2f5fb;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}
.monthly {
  font-family: sans-serif;
}
.DateWise {
  display: flex;
  justify-content: flex-end;
  margin-top: -3vw;
  margin-bottom: 1vw;
  background-color: #f2f5fb !important;
}
.css-1bzrz1e-MuiButtonBase-root-MuiButton-root {
  font-size: 1.5rem;
}

.css-4bn9vv-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: black;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px !important;
  padding-right: 14px;
}
.css-1va6w83-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px !important;
}
.css-1uwr9a-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(255, 255, 255) !important;
}

.notification {
  /* background-color: red; */
  position: relative;
  cursor: pointer;
}
.notification-icon {
  transform: scale(2);
  color: black;
}

.badge {
  position: absolute;
  top: -0.5vw;
  right: -1.1vw;
  padding: 0.2vw 0.5vw;
  border-radius: 50%;
  background-color: red;
  color: white;
   font-weight: bolder;
   font-family: sans-serif;
}
.closing-alerts-drop-down {
  position: absolute;
  box-shadow: 2px 2px 10px 4px rgba(0,0,0,.10);
  background-color: white;
  min-width: 20vw;
  max-width: fit-content;
  padding: 0.8vw 0vw;
  right: -1vw;
  top: 2vw;
  display: none;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
}


.notification:hover .closing-alerts-drop-down {
  display: flex;
}


.closing-alerts-drop-down .zero-Noti-Container{
  /* background-color: orange; */
  width: 100%;
  /* margin: 1vw; */

}

.closing-alerts-drop-down .zero-Notification {
  font-family: "Jost",sans-serif;
  font-size: 1.5vw;
  font-weight: bolder;
  color: #fe5010;
  /* background-color: red; */
}


.closing-alerts-drop-down .drop-down-row{
  display: flex;
  /* background-color: orange; */
  border-radius: unset;
  width: 90%;
  /* margin-left: auto;
  margin-right: auto; */
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  /* margin-top: 0.8vw; */
  /* border: 1px solid black; */
  margin: 0.1vw 0vw;
  /* border-bottom: 0.1vw  dotted rgb(199, 199, 199); */
}

.closing-alerts-drop-down .drop-down-row .icon{
  color: red;
  width: 3vw;
}

.closing-alerts-drop-down .drop-down-row .drop-down-key{
  font-family: "Jost",sans-serif;
  font-size: 1.2vw;
  font-weight: 600;
  width: 15vw;
  display: flex;
  padding: 0vw 0vw 0vw 0.5vw;
}

.closing-alerts-drop-down .drop-down-row .space-divider{
  /* background-color: red; */
  width: 2vw;
  font-family: "Jost",sans-serif;
}
.closing-alerts-drop-down .drop-down-row .drop-down-value{
  color: #fe5010;
  font-family: "Jost",sans-serif;
  font-size: 1.6vw;
  font-weight: bolder;
  width: 8vw;
  display: flex;
  padding-left: 0.8vw;
  letter-spacing: 1px;
  /* background-color: black; */

}









@media screen and (max-width: 768px) {
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    /* height: 1.4375em; */
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 8.5px 7px;
  }

  .insights {
    padding-top: 3vw;
    margin-left: 6vw;
  }
  .css-179jkyp-MuiFormLabel-root-MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;
    left: 0;
    margin-top: 0vw;
    top: 0;
  }
  .css-1ofocb3-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 3.5vw;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
  }
}
