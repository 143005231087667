* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 5.5vw;
  padding: 0vw 1.2vw;
  /* border-bottom: 1px solid rgb(143, 142, 142); */
  background: white !important;
  box-shadow: 1px 1px 2px grey;
}
h1 {
  font: sans-serif;
}

.Navbar h1 {
  color: orange;
  font-family: "Jost", sans-serif;
  font-size: 2.8vw;
}
.link {
  text-decoration: none;
  color: black;
}

.logOutBtn {
  color: black;
  cursor: pointer;
  /* background-color: red; */
}
.logOutBtn:hover {
  background-color: white !important;
}
.DropDownList {
  display: flex;
  justify-content: space-between;
  width: 25vw;
  align-items: center;
  text-align: center;
}
.error{
  color: #ff2400;
  font-family: "Jost", sans-serif;
}
.heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 2.4vw;
  margin: 1.8vw 0vw 0.5vw 5.2vw;
  text-decoration: none;
}

input {
  margin-left: auto;
  margin-right: auto;
}

form {
  margin-bottom: 5vw;
}
.SubmitInp {
  display: flex;
  margin-top: 5vw;
}
.SubmitInp input {
  padding: 0.8vw;
  font-size: 1.2vw;
  background-color: #ffa500;
  border: none;
  color: white;
  cursor: pointer;
}

.CatBTN {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin-top: 2.5vw;
}
.CatBTN button {
  padding: 0.4vw;
  color: white;
  border: none;
  background-color: #fe5010;
  font-size: 1.2vw;
  cursor: pointer;
}
.CatBTN button:hover {
  box-shadow: 2px 2px 4px gray;
  transform: scale(1.1, 1.1);
  transition-duration: 0.2s;
}

.dateCate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 1vw 0vw !important;
}
.SubmitBtn {
  padding: 0.4vw;
  color: white;
  border: none;
  background-color: #fe5010;
  font-size: 1.2vw;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vw;
}
.SubmitBtn:hover {
  box-shadow: 2px 2px 4px gray;
  transform: scale(1.1, 1.1);
  transition-duration: 0.2s;
}
.UpdateContainer .SubmitBtn {
  padding: 0.4vw;
  color: white;
  border: none;
  background-color: orange;
  font-size: 1.2vw;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.UpdateContainer .SubmitBtn:hover {
  box-shadow: 2px 2px 4px gray;
  transform: scale(1.1, 1.1);
  transition-duration: 0.2s;
}
.SubmitInp Link button {
  padding: 0.4vw;
  color: white;
  border: none;
  background-color: orange;
  font-size: 1.2vw;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
}
.SubmitInp {
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
}
.ConsumptionContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* flex-wrap: wrap; */
  text-align: center;
  margin-bottom: 2.5vw;
  border: none;
  /* padding: 0vw 5vw; */
}

.maintainanceIssue {
  margin: 2.5vw;
  margin-bottom: 2vw;
}
.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 3vw 0;
  font-family: "Jost", sans-serif;
}

.loginContainer {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}
.loginContainer div {
  /* margin: 1.2vw; */
  margin-top: 1.2vw;
  width: 19vw;
  font-size: 1.3vw;
}

.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.LoginFormHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: orange;
  font-family: "Jost", sans-serif;
  font-size: 2.2vw;
  margin-bottom: 2vw;
  margin-top: -1vw;
}

.donwloadBtn {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.donwloadBtn button a {
  text-decoration: none;
  color: white;
}
.donwloadBtn button {
  background-color: green;
  border: none;
  padding: 0.5vw;
  font-size: 1.2vw;
}
.donwloadBtn button:hover {
  box-shadow: 2px 2px 4px gray;
  transform: scale(1.1, 1.1);
  transition-duration: 0.2s;
}

.dashboard {
  background-color: #f2f5fb !important;
  padding: 1vw 3vw 0vw 3vw;
}

.css-1d6wzja-MuiButton-startIcon > *:nth-of-type(1) {
  font-size: 32px !important;
}

.DashboardNavbar {
  background-color: #f2f5fb;
  padding: 1.5vw 4vw 1vw 3.2vw;
  position: sticky;
  top: 0;
  z-index: 999;
}
.DashboardNavbar .HomeNavbar {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 0.9vw;
  padding: 0.2vw 0vw;
}
.HomeNavbar .profile{
  margin: 1rem;
}
.HomeNavbar .profile p{
  display: flex;
  align-items: center;
  font-family: "Jost", sans-serif;
}
.HomeNavbar .profile img{
  width: 2rem;
  margin-right: 1rem;
}
.HomeNavbar .logo {
  margin-left: auto;
  margin-right: auto;
}

.logo img {
  width: 13.5vw;
  padding: 0.6vw 0vw;
}
.ChartsNavbar h1 {
  color: #fe5010;
  font-family: sans-serif;
  font-size: 2.5vw;
  margin-right: 149px;
}
.formBTN {
  background-color: #fe5010 !important;
  width: 5vw !important;
  /* padding: 0.7vw !important; */
  margin-left: -2vw !important;
}

/* graph Components css here */
.Elements {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  padding: 0vw 7vw;
  background-color: #f2f5fb;
  font-family: "Poppins", sans-serif;
  width: 100vw;
  height: 68.5vh;
}
.card {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 3vw;
  /* border: 1px solid black; */
  padding: 2.5vw;
  border-radius: 10px;
}
.card h1 {
  color: #fe5010;
  font-size: 2vw;
  margin-right: 2vw;
}
.card span {
  color: black;
}
.DashboardContainer {
  background-color: #f2f5fb;
}

/* graphs editing here */
.categoriesGraph {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.graphsDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.categoriesGraph .yearlyGraph {
  width: 55vw;
}

.bulkGraph {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-top: 0.8vw;
  /* background-color: #F2F5FB; */
}
.bulkGraph div {
  width: calc(100vw / 4.5);
  /* border: 1px solid black; */
  /* border-radius: 1.8vw; */
  background-color: white !important;
  padding: 1.2vw;
  font-family: "Jost", sans-serif;
}

.bulkGraph div:hover {
  /* box-shadow: 2px 2px 4px gray; */
  /* transform: scale(1.1, 1.1);
  transition-duration: 0.2s; */
}
.categoriesGraph .catPie {
  width: 42vw;
}
.ConsumablesGraph {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-top: 1vw;
}
.PieChart {
  width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8vw 3.5vw 0.8vw 3.5vw;
  text-align: center;
  background-color: #f2f5fb !important;
  /* background-color: yellow; */
}
.PieChart .PieChartDonut {
  background-color: white;
  width: 30vw;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.5vw;
  margin-bottom: 1.5vw;
}
.PieChart h1 {
  margin: 0.5vw;
  font-size: 1.2vw;
  font-family: "Jost", sans-serif;
  margin: 1vw 0vw 1vw 0vw;
  /* background-color: white; */
}
.ConsumablesGraph .bar {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40vw;
  /* background-color: red; */
}
.categoriesGraph div {
  width: 35vw;
  margin: 0.8vw;
  padding: 1.5vw;
  background: white !important;
}

.categoriesGraph div h1 {
  margin-bottom: 1.2vw;
  font-family: "Jost", sans-serif;
  font-size: 1.2vw;
}
.Graphheading p {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: sans-serif;
  margin: 0vw 0vw;
  font-size: 2.6vw;
  color: #fe5010;
  font-weight: bolder;
}
.Graphheading {
  display: flex;
  justify-content: space-between;
  padding: 0vw 1vw;
  align-items: center;
  text-align: center;
}

.GraphDate {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding-top: 2vw; */
}
.Go {
  margin-left: 1vw;
  font-size: 1.3vw;
  font-family: sans-serif !important;
  padding: 0.6vw 0.8vw 0.6vw 0.8vw !important;
  box-shadow: 0px 0px 0px black !important;
  min-width: fit-content !important;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  background-color: #ff762d !important;
  border: none !important;
  color: white;
  border-radius: 4px;
}
.Go:hover {
  /* background-color: #fa6616 !important; */
  transform: scale(1.1);
}

.dateSection {
  /* margin-top: 1vw; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-left: -3vw;
}
.dateSection div {
  width: 10vw;
  margin-right: 1vw;
}
.lineBar {
  padding: 1vw 6vw;
}
.active {
  border-right: 3px solid #ffa500;
  color: #ffa500;
}
/* bulk  graph  received weekly*/
.consumablesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.OthersGraph {
  display: flex;
  justify-content: flex-start;
  font-family: sans-serif;
  align-items: center;
  text-align: center;
  /* height: 33.5vw; */
  /* flex-wrap: wrap; */
}
.OthersGraph div {
  width: 41vw;
  margin: 1.2vw;
  background: white !important;
  padding: 1.5vw;
  margin-top: 4vw;
  margin-bottom: 7vw;
}
.OthersGraph .Bulk {
  width: 55vw;
}
.divider {
  border-top: 1px dashed #c1c1c1;
  width: 28vw;
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
}
.alertsHeading {
  background-color: white !important;
  font-size: 1.2vw;
  /* margin-bottom: 1vw; */
  font-family: sans-serif;
  /* margin-top: 2.2vw; */
  /* padding: 0vw 0vw 1vw 0vw; */
}
.alerts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* flex-direction: column; */
  width: 30vw;
  text-align: center;
  background-color: white !important;
  padding: 1vw;
  flex-wrap: wrap;
}

.alerts div {
  width: 34.5%;
  /* margin-right: auto; */
  display: flex;
  flex-direction: column;
  /* width: 15vw; */
  background-color: white !important;
  margin: 0.3vw 0;
}
.alerts div header {
  display: flex;
  justify-content: space-between;
  font-size: 0.9vw;
  font-weight: bolder;
  color: gray;
  font-family: sans-serif;
}
.infoIcon {
  margin-left: 3vw;
  color: black;
  display: inline-block;
  position: relative;
}
.oRings p {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.8vw;
  font-weight: 100;
  line-height: 5px;
}
.oRings {
  visibility: hidden;
  width: 10rem;
  color: black;
  background-color: #fff;
  padding: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 10px 10px 40px #bebebe8f;
  text-align: center;
  position: absolute;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  z-index: 1;
  right: 0;
  margin-top: 1.2rem;
}
.info {
  color: green;
}
.alert {
  color: yellow;
}
.moreAlert {
  color: red;
}

.infoIcon:hover .oRings {
  visibility: visible;
}

.alerts div h1 {
  font-size: 1.2vw;
  margin-right: auto;
  margin-top: -0.9vw;
  font-family: sans-serif;
  color: rgb(41, 41, 41);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.TickIcon {
  display: flex;
  margin-left: -0.2vw;
  color: green;
}

.closingGraph {
  margin-top: 1vw;
}
.closingGraph .tillDate {
  /* width: 35vw; */
  background-color: white;
  padding: 0.9vw;
  margin-left: 1.1vw;
  margin-top: 1.1vw;
}
.closingGraph .ClosingEmptyGood {
  width: 32vw;
  background: white;
  padding: 1.2vw 2vw 2vw 2vw;
  margin-left: 1.2vw;
  margin-top: 1vw;
}
.closingGraph .ClosingEmptyGood h1 {
  font-family: "Jost", sans-serif;
  margin: 0.5vw;
  font-size: 1.2vw;
}
.tillDate h1 {
  font-family: "Jost", sans-serif;
  margin: 1.1vw;
  font-size: 1.2vw;
}

.GainLoss {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Jost",sans-serif;
  text-align: center;
  /* margin-top: 0.8vw; */
  margin-top: auto;
  background-color: white !important;
  padding: 0.8vw;
  border-radius: 0.9vw;
}
.GainLoss div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white !important;
}
.GainLoss div p {
  /* margin-top: auto; */
  color: black !important;
  font-family: "Jost",sans-serif;

}
.GainLoss div h1 {
  font-size: 1.8vw;
}
.LossRed {
  color: #ff2400;
}
.GainGreen {
  color: #00ff00;
}
.GainLossTwo div h1 {
  font-size: 1.5vw;
  /* color: Green; */
}
.GainLoss div p {
  /* font-weight: bolder; */
  font-size: 1.1vw;
  font-family: "Jost",sans-serif;

}

.Defective {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Jost", sans-serif;
  /* width: 15vw; */
}
.Defective div {
  width: 23vw;
}

.css-4bn9vv-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: black;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px !important;
  padding-right: 14px;
}
.css-1bzrz1e-MuiButtonBase-root-MuiButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  /* min-width: 64px; */
  padding: 6px 16px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #fe5010;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-left: 16px;
}

/* form element styling */
.css-rvw4uo-MuiPaper-root-MuiTableContainer-root {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  width: 100%;
  overflow-x: auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

/* date picker settijng color to white */
.css-4bn9vv-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  padding-right: 14px;
  background: white !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  background: white !important;
}
.css-1va6w83-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background-color: white !important;
}
.css-2bmzhh-MuiPaper-root-MuiTableContainer-root {
  background-color: white !important;
}
/* .css-1uwr9a-MuiButtonBase-root-MuiButton-root:hover{
  background-color: white;
} */
.css-1uwr9a-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(255, 255, 255) !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  /* height: 1.4375em; */
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 8.5px 7px;
}

.insights {
  padding-top: 3vw;
  margin-left: 6vw;
}
.css-179jkyp-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 3vw;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  left: 0;
  margin-top: 0vw;
  top: 0;
}
.css-1ofocb3-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 3.5vw;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
}
