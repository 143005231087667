.upload-sheets{
    font-family: "Jost", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    height: 100%;
    width: 100%;
    margin: 3rem 0;
    @media screen and (max-width:768px) {
        margin: 0;
        padding: 6rem 0;
    }
    .radio-btn-group{
        flex-direction: row;
    }
    .input-field-container{
        display: flex;
        align-items: center;
        P{
            margin-right: 2rem;
        }
    }
    .form{
        @media screen and (max-width:768px) {
            flex-direction: column;
            align-items: center;
            margin: 3rem 0;
        }
        margin: 4rem 0;
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        .input-field-container{
            @media screen and (max-width : 768px) {
                margin: 1rem 0;
                width: 100%;
                input{
                    width: 100%;
                }
            }
            .select-el{
                margin: 0 2rem;
            }
            margin: 0 3rem;
            // background-color: #fff;
        }
    }
    .error{
        p{
            color: #f00;
        }
    }
}

