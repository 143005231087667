@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");
* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.Nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2vw 6vw;
  text-align: center;
  border-bottom: 1px solid gainsboro;
}
.Nav img {
  width: 15vw;
}
.insights {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  padding: 1vw;
  background-color: #f2f5fb;
  margin-left: auto;
}
.insights .GoBTN {
  font-size: 1.3vw;
  font-family: "Jost", sans-serif !important;
  padding: 0.2vw 0.4vw 0.2vw 0.4vw !important;
  box-shadow: 0px 0px 0px black !important;
  min-width: fit-content !important;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  background-color: #ff762d !important;
  /* margin-top: auto; */
}
.insights .GoBTN:hover {
  /* background-color: #fa6616 !important; */
  transform: scale(1.1);
}

.btn {
  padding: 0.3vw;
  margin: 0 0.8vw;
  font-size: 1vw;
  background-color: #ff762d;
  color: white;
  font-family: sans-serif;
  border: none;
  cursor: pointer;
}
.Analytics {
  padding: 0.3vw;
  margin: 0 0.8vw;
  font-size: 1vw;
  background-color: #ff762d;
  color: white;
  font-family: sans-serif;
  border: none;
  cursor: pointer;
}
.Analytics:hover {
  box-shadow: 2px 2px 4px gray;
  transform: scale(1.1, 1.1);
  transition-duration: 0.2s;
}
.insights .btn:hover {
  box-shadow: 2px 2px 4px gray;
  transform: scale(1.1, 1.1);
  transition-duration: 0.2s;
}
.DashBoard {
  background-color: #f2f5fb;
}

.content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f2f5fb !important;
  margin-top: -2vw;
}

.content header {
  display: flex;
  font-family: sans-serif;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  /* background-color: red; */
}
.content header .heading {
  display: flex;
  justify-content: flex-start !important;
  background-color: white !important;
  flex-direction: column;
  border-radius: 0.9vw;
  width: 71vw;
  padding: 0.5vw 0vw 0.5vw 3vw;
  font-size: 3.8vw;
  font-family: "Jost", sans-serif;
  line-height: 7vw;
  text-align: left;
  align-items: baseline;
}
.content .cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f2f5fb;
  text-align: center;
  margin-top: -1.7vw;
  padding-bottom: 3.4vw;
}

.content .headerCard {
  background-color: #f2f5fb;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

.headerCard div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 2.4vw;
  width: 23vw;
  border-radius: 0.5vw;
  background-color: white !important;
  font-family: sans-serif;
}

.cards div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1.5vw;
  width: 35vw;
  border-radius: 0.5vw;
  background-color: white;
  font-family: sans-serif;
}
.headerCard div h1 {
  font-size: 2vw;
  color: #ff762d;
  font-family: "Jost", sans-serif;
}
.cards div h1 {
  font-size: 2.5vw;
  color: #ff762d;
  font-family: "Jost", sans-serif;
}
.headerCard div span {
  margin-top: 2vw;
  font-size: 4vw;
  font-weight: bolder;
}
.cards div span {
  margin-top: 2vw;
  font-size: 4vw;
  font-weight: bolder;
}

.alertIcon {
  color: green;
  font-size: 1.8vw;
}

/* border radius for date picker */
.css-4bn9vv-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: black;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px !important;
  padding-right: 14px;
}
.PowerConmpMT h1 {
  margin: 0.5vw;
  font-family: "Jost", sans-serif;
}
.css-1uwr9a-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(255, 255, 255) !important;
}

/* mobile css from here */
.mobileView {
  display: none;
}
/*==================== GOOGLE FONTS ====================*/
/* @font-face {
  font-family: "pattana";
  src: url("../fonts/Pattanakarn-Regular.ttf");
}
@font-face {
  font-family: "AgrandirTextBold";
  src: url("../fonts/Agrandir-TextBold.otf");
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@font-face {
  font-family: "calibri";
  src: url("../fonts/FontsFree-Net-calibri-bold.ttf");
}
@font-face {
  font-family: "gilory";
  src: url("../fonts/Gilroy-Bold.ttf");
}
@font-face {
  font-family: "Siffon";
  src: url("../fonts/sifonnbasic/sifonnbasic/SIFONN_BASIC.otf");
}
@font-face {
  font-family: "newAgrandir";
  src: url("../fonts/Agrandir-Narrow.otf");
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900;600&display=swap");
==================== VARIABLES CSS ==================== */
:root {
  /* variables */
  --gradient-color-one: #579d9f;
  --gradient-color-two: #121729;
}
/*==================== BASE ====================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--gradient-color-two);
}
html {
  scroll-behavior: smooth;
}

body {
  /* overflow: hidden; */
  background-color: whitesmoke;
}

header {
  display: none;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
img {
  width: 100%;
}
.MobileImage {
  display: none;
}
.categoriesGraph div h1 span {
  font-size: 0.6vw;
  font-weight: 400;
  color: gray;
}
.PowerConmpMT h1 span {
  font-size: 0.9vw;
  font-weight: 400;
  color: gray;
}
.css-quj9j6-MuiTable-root {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 650px !important;
}
.css-1ogvb49 {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.css-1nrlq1o-MuiFormControl-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  /* display: flex; */
  /* justify-content: center; */
  /* padding: 24vw; */
  margin-left: 0vw !important;
  /* margin-right: auto; */
}

.css-13y5u2s {
  min-width: 300px !important;
}
.categoriesGraph div h1 span {
  font-size: 0.6vw;
  color: gray;
  font-weight: 400;
}
.MobileNavbar {
  display: none;
}
.deskStopForm {
  display: block;
}
.updateNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white !important;
  z-index: 999;
  box-shadow: 1px 1px 2px gray;
  padding: 0.7vw 1.2vw;
  position: sticky;
  top: 0;
}
.box:first-child {
  margin-right: auto;
  color: #ff762d;
}
.backIcon {
  color: #ff762d;
  font-size: 3vw !important;
}
.box:last-child {
  /* margin-left: auto; */
  margin-right: auto;
}
.box:last-child img {
  font-family: sans-serif;
  color: #ff762d;
  width: 15vw;
}
.updateNavbar .box:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.css-181usli-MuiGrid-root {
  padding-left: 5vw !important;
  padding-right: 6vw !important;
}

.bulkGraph div h1 span {
  font-size: 0.6vw;
  color: gray;
  font-weight: 400;
}

.notification-settler {
  /* background-color: red !important; */
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  text-align: center;
}
.notification-settler div .GoBTN {
  margin-top: 0.5vw;
}
.MobileLicense {
  padding-top: 15vw;
}

.alignment-adjustor {
  opacity: 0 !important;
  background-color: red !important;
  /* margin-top: 5vw; */
  color: red;
}

.Closing-Alert-Value {
  background-color: red;
  display: flex;
  min-width: 6vw;
  max-width: fit-content;
  height: 1vw !important;
  justify-content: flex-start;
  /* margin-top: 0.6vw !important; */
}

.Closing-Alert-Value h1{
  padding: 0.6vw 0.5vw;
}


/* setting media queires on higher devices  */
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .OthersGo {
    margin-left: 6vw;
  }
}

/* For large devices */
@media screen and (max-width: 768px) {
  /* global */
  body {
    overflow-y: visible;
    overflow-x: hidden !important;
    background-color: #f2f5fb !important;
  }
  .DashHeading {
    display: flex;
    justify-self: center;
    align-items: center;
    text-align: left;
    background-color: white !important;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2.5vw;
    padding: 2vw;
    font-family: "Jost";
    margin: 2.5vw;
    /* height: 40vw; */
  }
  .DashHeading div h1 {
    font-size: 10vw;
  }
  .DashHeading div h1 span {
    color: #ff762d;
  }
  @supports (-webkit-touch-callout: none) {
    .DashHeading {
      display: flex;
      justify-self: center;
      align-items: center;
      text-align: left;
      background-color: white !important;
      margin-left: auto;
      margin-right: auto;
      border-radius: 2.5vw;
      padding: 2vw;
      font-family: "Jost", sans-serif;
      margin: 2.5vw;
      /* height: 35vw; */
      margin-top: 5vw;
    }
    .DashHeading div h1 {
      font-size: 9.2vw;
      font-weight: 900;
      font-family: "Jost", sans-serif;
    }
  }
  .TankersData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    /* background-color: red; */
    margin: 2.5vw;
  }
  .TankersData .CardHolder {
    display: flex;
    /* background-color: red; */
    justify-content: space-around;
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
    /* padding: 2vw;  */
  }
  .TankersData .CardHolder .DashCards {
    background-color: white;
    border-radius: 2.5vw;
    width: 50vw;
    height: 30vw;
    margin: 1vw;
    padding: 2vw;
  }
  .DashCards h1 {
    font-size: 4vw;
    font-family: "Jost", sans-serif;
    color: #ff762d;
  }

  .Value {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 0vw;
  }
  .Value h1 {
    font-size: 12vw;
    color: black;
  }
  @supports (-webkit-touch-callout: none) {
    .DashCards h1 {
      font-size: 4vw;
      font-family: "Jost", sans-serif;
      color: #ff762d;
    }
    .Value h1 {
      font-size: 12vw;
      color: black;
    }
  }

  .Value .icon {
    color: green;
    font-size: 5vw;
    margin-top: 5vw;
  }

  .CanvasBar {
    height: 55vw !important;
  }
  .css-6xugel > :nth-of-type(1) {
    font-size: 7vw !important;
  }

  .css-go2vvz-MuiGrid-root {
    /* height: 34vw !important; */
  }
  .UpdateContainer .SubmitBtn {
    padding: 1.4vw;
    color: white;
    border: none;
    background-color: #ff762d;
    font-size: 4.2vw;
    cursor: pointer;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .dateCate {
    padding-left: 5vw;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left !important;
    /* flex-direction: column; */
    margin: 3vw 0vw;
    flex-wrap: wrap;
    /* background: red; */
    /* height: 40vw; */
  }
  .css-yq161r {
    min-width: 121px !important;
    padding-left: unset !important;
    padding-right: 5vw !important;
    margin-top: 2vw !important;
    margin-bottom: 2vw !important;
    margin-left: 1vw !important;
  }
  .css-q8hpuo-MuiFormControl-root {
    min-width: 29vw;
  }

  .SubmitBtn {
    padding: 1.4vw;
    color: white;
    border: none;
    background-color: #ff762d;
    font-size: 4.2vw;
    cursor: pointer;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vw;
  }
  .backIcon {
    color: #ff762d;
    font-size: 6.5vw !important;
  }
  .updateNavbar {
    padding: 2.7vw 1.2vw;
  }
  .box:last-child img {
    font-family: sans-serif;
    color: #ff762d;
    width: 40vw;
  }
  .demoLabel {
    margin-left: 3.5vw !important;
  }
  .PreviewSelectionMobile {
    margin-left: 4vw !important;
  }
  .LoginFormHeading h1 {
    font-size: 7.5vw;
    color: #ff762d !important;
    font-family: "Jost", sans-serif;
    font-weight: 700;
  }
  .loginContainer div {
    width: 50vw;
    font-size: 4.3vw;
  }
  .ImageHeading {
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: auto;
  }
  .logOutBtn:last-child {
    margin-left: auto !important;
  }
  .heading {
    font-size: 8vw;
    margin-top: 5vw;
  }
  .deskStopForm {
    display: none;
  }
  .MobileFilling,
  .MobileConsumables,
  .MobileBattery,
  .MobileMaintainence,
  .MobileBulk,
  .MobilePower {
    padding-top: 15vw;
  }
  .Navbar {
    display: none;
  }
  .MobileNavbar {
    display: block;
    background-color: white;
    color: #ff762d;
  }

  .MobileNavbar nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 2vw 5vw;
  }
  .MobileNavbar nav .img {
    width: 35vw;
  }

  .ConsumptionContainer {
    flex-wrap: wrap;
    padding: 0vw 5vw;
  }
  .categoriesGraph div h1 span {
    font-size: 1.6vw;
    color: gray;
    font-weight: 400;
  }

  .dateSection {
    flex-direction: row;
    text-align: left;
    /* align-items: baseline; */
    margin-left: 5vw;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
  }
  .GainLoss {
    margin: 2vw 0vw 1vw auto;
    padding: 1.5vw;
    border-radius: 2.5vw;
  }
  .Go {
    font-size: 5.3vw;
    margin: 2vw;
  }
  .css-1ro5inb-MuiGrid-root {
    flex-direction: row !important;
  }

  .OthersGraph .Bulk {
    width: 95vw;
  }
  .insights .GoBTN {
    padding: 2vw !important;
    font-size: 4.3vw;
  }
  .css-1ofocb3-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: white !important;
  }
  .insights {
    margin-left: 2.5vw;
  }
  .categoriesGraph .yearlyGraph {
    width: 95vw;
  }
  .GainLoss div p {
    font-size: 4vw;
  }
  .GainLoss div h1 {
    font-size: 7vw;
  }
  .Graphheading {
    padding: 0vw 4vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .GraphDate {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
  }
  .GraphDate div {
    margin-top: 1vw;
  }
  .dateSection div {
    width: unset !important;
    margin: 0.4vw 0.1vw;
  }
  .css-13y5u2s {
    min-width: 28vw !important;
    margin-left: 1vw;
  }
  .DashboardNavbar,
  .dashboard {
    display: none;
  }
  .mobileView {
    display: block;
  }

  header {
    display: block;
  }
  /* header */
  header {
    display: block;
  }
  header nav {
    padding: 0.5rem 1.5rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    position: fixed;
    width: 100vw;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px #00000042;
  }
  nav .title {
    font-size: 2rem;
    font-family: "Jost", sans-serif;
    /* -webkit-font-feature-settings:"smcp"; */
    /* background: linear-gradient(
      90deg,
      var(--gradient-color-one),
      var(--gradient-color-two) 80%
    ); */
    background-color: #ff762d;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  #menuToggle {
    display: block;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    align-self: center;
  }
  #menuToggle a {
    text-decoration: none;
    color: #232323;
    transition: color 0.3s ease;
  }
  #menuToggle input {
    display: block;
    position: absolute;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 7; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  #menuToggle span {
    display: block;
    width: 1.75rem;
    height: 0.2rem;
    margin-bottom: 5px;
    position: relative;
    background-color: black;
    border-radius: 4px;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.6s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }
  #menuToggle span:nth-child(3) {
    width: 1.2rem;
  }

  #menuToggle span:nth-child(4) {
    width: 0.6rem;
    margin-bottom: 0;
  }
  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
* Transform all the slices of hamburger
* into a crossmark.
*/
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(405deg) translate(-2px, -1px);
  }

  /*
* But let's hide the middle one.
*/
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.8, 0.2);
  }
  #menuToggle input:checked ~ span:nth-last-child(2) {
    width: 1.75rem;
    transform: rotate(-45deg) translate(1px, -2px);
  }

  /*
* Make this absolute positioned
* at the top left of the screen
*/
  #menu {
    position: fixed;
    width: 70vw;
    /* opacity: 0; */
    height: 100vh;
    margin-left: -2rem;
    padding: 4rem 2rem;
    top: 0;
    background: #fff;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.4s ease-in-out;
  }

  #menu li {
    padding: 5vw 0;
    font-size: 5vw;
    text-align: left;
    font-family: "Gilory", sans-serif;
    font-weight: 600;
    /* border-bottom: 1px solid #c1c1c1; */
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }
  .logo-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
  }
  .logo-mobile img {
    width: 42vw;
    margin-right: 0.2vw;
  }
  .downloadBtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    /* margin-top: auto; */
  }
  .downloadBtn button {
    margin: 1.5vw 1.5vw 1.5vw 0vw;
    padding: 1.2vw;
    background-color: #ff762d;
    font-size: 5vw;
    color: white;
    border: none;
  }

  .content .cards {
    justify-content: center;
    flex-direction: column;
    padding: 0vw 6vw;
    margin-top: 0.9vw;
  }
  .cards div {
    margin: 1vw;
    width: 87vw;
  }
  .cards div h1 {
    font-size: 4vw;
    font-family: "Jost", sans-serif;
  }
  .cards div span {
    font-size: 8vw;
  }
  .content header {
    justify-content: center;
    padding: 0 6vw;
    flex-direction: column;
    height: 53.5vw;
  }
  /* css only for IOS devices only */
  @supports (-webkit-touch-callout: none) {
    .content header {
      font-family: jost;
      height: 63.5vw;
    }
  }
  /* css other than ios devices like windows, firefoxs */
  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
  }
  .content header .heading {
    width: 88vw;
    padding: 3.9vw 0vw 0.9vw 2vw;
    /* flex-direction: row; */
    /* line-height: 5vw; */
    text-align: left;
    margin-right: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  .content header .heading h1 {
    font-size: 9vw;
    margin-top: auto;
    font-family: "Jost", sans-serif !important;
    -webkit-font-family: "Jost", sans-serif !important;
    margin-bottom: auto;
    line-height: 10.5vw;
    letter-spacing: 0.2vw;
    -webkit-letter-spacing: 0vw;
  }
  @supports (-webkit-touch-callout: none) {
    .content header .heading h1 {
      font-size: 9vw;
      margin-top: auto;
      font-family: "Jost", sans-serif !important;
      -webkit-font-family: "Jost", sans-serif !important;
      margin-bottom: auto;
      line-height: 10.5vw;
      letter-spacing: 0.2vw;
      -webkit-letter-spacing: 0vw;
    }
  }
  .content .headerCard {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2vw;
  }
  .headerCard div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 2.4vw;
    width: 87vw;
    border-radius: 0.9vw;
    background-color: white !important;
    font-family: "Jost", sans-serif;
  }
  .headerCard div h1 {
    font-size: 4vw;
    font-family: "Jost", sans-serif;
  }
  .headerCard div span {
    margin-top: 2vw;
    font-size: 9vw;
  }
  #Home {
    padding-top: 22vw;
    margin-top: auto;
    overflow: hidden !important;
  }
  #Production,
  #Consumables,
  #Others,
  #Preview {
    padding-top: 16vw;
  }
  /* #Consumables{
    padding-top: 16vw;
  }
  #Others{
    padding-top: 16vw;
  }
  #Preview{
    padding-top: 16vw;
  } */

  /* date section editing input  */
  .sectionHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin: 5vw; */
    /* background-color: red; */
    font-family: "Jost", sans-serif;
    /* border-top: 1px dotted gray; */
    padding-top: 5vw;
  }
  .sectionHeading h1 {
    /* text-decoration: underline; */
    /* background-color: white; */
    font-size: 6vw;
    margin-bottom: 5vw;
  }

  .categoriesGraph {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .categoriesGraph div {
    width: 92vw;
    margin-bottom: 4vw;
    border-radius: 2.5vw;
  }
  .categoriesGraph div h1 {
    font-size: 4.2vw;
  }
  .categoriesGraph div h1 span {
    font-size: 2vw;
  }
  .bulkGraph {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .bulkGraph div {
    width: 45vw !important;
    margin-bottom: 2vw;
    border-radius: 0.9vw;
  }
  .bulkGraph div h1 {
    color: black !important;
    font-size: 3vw;
    font-weight: bolder;
  }
  .bulkGraph div h1 span {
    font-size: 1.6vw;
    font-weight: 400;
  }

  .consumablesWrapper {
    flex-direction: column;
  }
  .PieChart {
    width: 97vw;
    justify-content: space-around;
    flex-direction: column;
  }
  .PieChart .PieChartDonut {
    border-radius: 0.9vw;
    width: 92vw;
    flex-direction: column;
    display: flex;
  }
  .PieChartj .PieChartDonut h1 {
    font-size: 3.2vw;
    margin: 1vw;
  }
  .alertsHeading {
    background-color: white !important;
    font-size: 1.2vw;
    /* margin-bottom: 1vw; */
    font-family: "Jost", sans-serif;
    /* margin-top: 2.2vw; */
    /* padding: 0vw 0vw 1vw 0vw; */
    /* margin-left: 4vw; */
    width: 92vw;
    border-radius: 0.9vw;
  }
  .alerts {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* flex-direction: column; */
    width: 79vw;
    text-align: center;
    background-color: white !important;
    /* padding: 1vw; */
    /* margin-left: 8vw; */
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }
  .alerts div {
    width: 34.5%;
    /* margin-right: auto; */
    display: flex;
    flex-direction: column;
    /* width: 15vw; */
    background-color: white !important;
    margin: 0.3vw 0;
  }

  .alerts div header {
    align-items: center;
    text-align: center;
    font-size: 2.9vw;
  }
  .alerts div h1 {
    font-size: 6.2vw;
    margin-bottom: 5vw;
  }
  .PieChart h1 {
    font-size: 5.2vw;
    margin: 4vw 0vw;
  }
  .oRings {
    width: 35vw !important;
  }
  .oRings p {
    font-size: 2.8vw;
  }
  .closingGraph .ClosingEmptyGood,
  .closingGraph .tillDate {
    width: 92vw;
    border-radius: 0.9vw;
  }

  .closingGraph .ClosingEmptyGood h1,
  .tillDate h1 {
    font-size: 4.2vw;
    margin: 2vw;
  }

  .OthersGraph {
    flex-direction: column;
  }
  .OthersGraph div {
    width: 93vw;
    margin-bottom: 0vw;
  }
  .Defective div {
    width: 92vw;
  }
  .PowerConmpMT h1 span {
    font-size: 1.9vw;
    font-weight: 400;
    color: gray;
  }

  .css-quj9j6-MuiTable-root {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    min-width: 0px !important;
  }

  .css-1ogvb49 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .css-1nrlq1o-MuiFormControl-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    /* display: flex; */
    /* justify-content: center; */
    /* padding: 24vw; */
    margin-left: 0vw !important;
    /* margin-right: auto; */
  }
  .active {
    border-right: unset !important;
  }
  @supports (-webkit-touch-callout: none) {
    .active {
      border-right: unset !important;
    }
    .components {
      padding-bottom: 6vw !important;
    }
  }

  @supports not (-webkit-touch-callout: none) {
    .active {
      border-right: unset !important;
    }
  }

  /* end of 725 px */
}

/* mobile version Toggle Btn */
@media screen and (max-width: 400px) {
  .css-1ofocb3-MuiInputBase-root-MuiOutlinedInput-root {
    width: 40vw !important;
  }
  .active {
    border-right: unset !important;
  }
}
